import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <p className="footer-text">Thank you for visiting!</p>
      <p className="footer-text">Alex Zaloga, 2023</p>
    </div>
  );
}

export default Footer;
