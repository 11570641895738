import "./About.scss";
import React, { useEffect } from "react";

import Profile from "../../assets/Images/Untitled design (21).svg";

function About() {
  useEffect(() => {
    const projectSections = document.querySelectorAll(
      ".contact-picture__container, .contact-flex"
    );

    const animateSectionsOnScroll = () => {
      projectSections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight * 0.8) {
          section.classList.add("animate");
        } else {
          section.classList.remove("animate");
        }
      });
    };

    window.addEventListener("scroll", animateSectionsOnScroll);
    animateSectionsOnScroll(); // Trigger initial animation on component mount

    return () => {
      window.removeEventListener("scroll", animateSectionsOnScroll);
    };
  }, []);
  return (
    <div className="about">
      <h2 className="contact-header">About Me</h2>

      <div className="about-flex">
        <div className="contact-flex">
          <div className="contact-picture__container">
            <h3 className="contact-nameheader">Alex Zaloga</h3>
            <h4 className="contact-subheader"> (she/her)</h4>
            <img className="contact-picture" src={Profile} alt="" />
            <h3 className="contact-nameheadertablet">Alex Zaloga</h3>
            <h4 className="contact-subheadertablet"> (she/her)</h4>
          </div>
        </div>
        <div className="contact-flex">
          <h3 className="contact-nameheaderdesktop">Alex Zaloga</h3>
          <h4 className="contact-subheaderdesktop"> (she/her)</h4>
          {/* <h3 className="contact-copy__header">Hello 🌎</h3> */}
          <h3 className="contact-copy">
            I'm Alex, an experienced operations professional turned full-stack
            software engineer. Recently graduated from BrainStation Bootcamp, I
            bring diverse skills and a passion for crafting exceptional user
            experiences that drive business success. Thanks to my operations
            background, my work is infused with a strong focus on efficiency,
            organization and meticulous attention to detail.
          </h3>
          <h3 className="contact-copy">
            I find great joy in the fascinating process of transforming code
            into captivating, user-friendly websites and applications by
            seamlessly blending logic and artistic design. I am deeply committed
            to advancing inclusivity, accessibility, and diversity in the tech
            industry.
          </h3>
          <h3 className="contact-copy">
            If you're seeking a dedicated and adaptable full-stack software
            engineer, I'd love to connect and virtually chat. Feel free to share
            your favorite coffee shops or vintage shopping spots in Toronto too!
            I'm excited to contribute my expertise and passion to your team.
            Let's create something amazing together!
          </h3>
        </div>
      </div>
    </div>
  );
}

export default About;
