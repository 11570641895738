import React, { useEffect } from "react";
import "./Projects.scss";
import curby from "../../assets/Images/Untitled design (24).svg";
import amex from "../../assets/Images/amexsvg.svg";
import ball from "../../assets/Images/newmagic8ball.svg";
import todo from "../../assets/Images/todossvg.svg";

function Projects() {
  useEffect(() => {
    const projectSections = document.querySelectorAll(".projects-container");

    const animateSectionsOnScroll = () => {
      projectSections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight * 0.8) {
          section.classList.add("animate");
        } else {
          section.classList.remove("animate");
        }
      });
    };

    window.addEventListener("scroll", animateSectionsOnScroll);
    animateSectionsOnScroll(); // Trigger initial animation on component mount

    return () => {
      window.removeEventListener("scroll", animateSectionsOnScroll);
    };
  }, []);

  return (
    <div className="projects">
      <h2 className="projects-header">Projects</h2>
      <div className="projects-container">
        <h3 className="projects-subheader">Curby</h3>

        <h4 className="projects-description">
          An app for sharing free curb finds & promoting sustainability through
          community engagement.
        </h4>

        <img
          className="projects-curby__image"
          src={curby}
          alt="curby mockup in desktop and mobile view"
          autoPlay
        />

        <p className="projects-techstack">
          Tech Stack: React, Node JS, Express, REST API, SASS.
        </p>
        <div className="projects-buttons">
          {/* <a
            href="https://www.curby.ca"
            target="_blank"
            rel="noopener noreferrer"
            className="projects-link"
            role="button"
          >
            <span className="projects-buttons__text">Live (TBD)</span>
          </a> */}
          <a
            href="https://github.com/alexzaloga7/Curby"
            target="_blank"
            rel="noopener noreferrer"
            className="projects-link"
            role="button"
          >
            <span className="projects-buttons__text">Github Repo</span>
          </a>
          <a
            href="https://www.loom.com/share/974cfc07c5814bf08c09d367f049fd84"
            target="_blank"
            rel="noopener noreferrer"
            className="projects-link"
            role="button"
          >
            <span className="projects-buttons__text">Demo</span>
          </a>
        </div>

        <div className="projects-video__container"></div>
      </div>
      <div className="projects-container">
        <h3 className="projects-subheader">Amex Industry Project</h3>
        <h4 className="projects-description">
          24hr hackathon to create a digital solution for Amex who were seeking
          improvements in customer service utilizing emerging technologies such
          as AI/ML. Worked collaboratively with a cross-disciplinary team of UX
          Designers, Data Scientist and Software Engineers. We were selected as
          the top-performing team by Amex & received an award for our
          achievement.
        </h4>
        <img
          className="projects-amex__image"
          src={amex}
          alt="amex project mock up in desktop view"
          autoPlay
        />

        <p>Tech Stack: React, Node JS, Express.</p>

        <div className="projects-buttons">
          <a
            href="https://github.com/alexzaloga7/Industry-Project"
            target="_blank"
            rel="noopener noreferrer"
            className="projects-link"
            role="button"
          >
            <span className="projects-buttons__text">Github Repo</span>
          </a>
        </div>
      </div>
      <div className="projects-container">
        <h3 className="projects-subheader">Magic 8 Ball</h3>
        <h4 className="projects-description">
          4 hour hackathon to create a application using Javascript & external
          API. Magic 8 Ball application has all the answers you need!
        </h4>
        <img
          className="projects-ball__image"
          src={ball}
          alt="magic eight ball project mock up in mobile view"
          autoPlay
        />

        <p className="projects-techstack">
          Tech Stack: Javascript, DOM API, CSS
        </p>

        <div className="projects-buttons">
          <a
            href="https://github.com/alexzaloga7/Magic-8-Ball-Hackathon"
            target="_blank"
            rel="noopener noreferrer"
            className="projects-link"
            role="button"
          >
            <span className="projects-buttons__text">Github Repo</span>
          </a>
        </div>
      </div>
      <div className="projects-container">
        <h3 className="projects-subheader">Bootcamp Survival Guide</h3>
        <h4 className="projects-description">
          A Bootcamp Survival Guide daily to do list using React & built own API
          using Express. Worked collaboratively using GitFlow.
        </h4>
        <img
          className="projects-todo__image"
          src={todo}
          alt="to do list project mock up in desktop view"
          autoPlay
        />
        <p className="projects-techstack">
          Tech Stack: REACT, Node.JS, Express, Sass
        </p>
        <div className="projects-buttons">
          <a
            href="https://github.com/heechanku/pairprogramming"
            target="_blank"
            rel="noopener noreferrer"
            className="projects-link"
            role="button"
          >
            <span className="projects-buttons__text">Github Repo</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Projects;
