import React, { useEffect } from "react";

import "./Skills.scss";
import JS from "../../assets/Icons/Logo/js.svg";
import Html from "../../assets/Icons/Logo/html.svg";
import Reacticon from "../../assets/Icons/Logo/react.svg";
import Sass from "../../assets/Icons/Logo/sass.svg";
import Node from "../../assets/Icons/Logo/node.svg";
import Express from "../../assets/Icons/Logo/express.svg";
import Mysql from "../../assets/Icons/Logo/sql.svg";
import Accessibility from "../../assets/Icons/Logo/accessibility.svg";
import Responsive from "../../assets/Icons/Logo/responsive.svg";
import Github from "../../assets/Icons/Logo/github.svg";

function Skills() {
  useEffect(() => {
    const skillsBoxes = document.querySelectorAll(".skills-box");

    const animateBoxesOnScroll = () => {
      skillsBoxes.forEach((box) => {
        const boxTop = box.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (boxTop < windowHeight * 0.8) {
          box.classList.add("animate");
        } else {
          box.classList.remove("animate");
        }
      });
    };

    window.addEventListener("scroll", animateBoxesOnScroll);
    animateBoxesOnScroll(); // Trigger initial animation on component mount

    return () => {
      window.removeEventListener("scroll", animateBoxesOnScroll);
    };
  }, []);

  return (
    <div className="skills">
      <h2 className="skills-header">Skills</h2>
      <div className="skills-grid">
        <div className="skills-box">
          <img className="skills-icon" src={JS} alt="javascript icon" />
          <p className="skills-text">JAVASCRIPT</p>
        </div>
        <div className="skills-box">
          <img className="skills-icon" src={Html} alt="" />
          <p className="skills-text">HTML 5</p>
        </div>

        <div className="skills-box">
          <img className="skills-icon" src={Reacticon} alt="react icon" />
          <p className="skills-text">REACT</p>
        </div>
        <div className="skills-box">
          <img className="skills-icon" src={Sass} alt="sass icon" />
          <p className="skills-text">SASS</p>
        </div>
        <div className="skills-box">
          <img className="skills-icon" src={Node} alt="node icon" />
          <p className="skills-text">NODE</p>
        </div>
        <div className="skills-box">
          <img className="skills-icon" src={Express} alt="express icon" />
          <p className="skills-text">EXPRESS</p>
        </div>
        <div className="skills-box">
          <img className="skills-icon" src={Mysql} alt="sql icon" />
          <p className="skills-text">SQL</p>
        </div>
        <div className="skills-box">
          <img
            className="skills-icon"
            src={Accessibility}
            alt="accessibility icon"
          />
          <p className="skills-text">ACCESSIBILITY</p>
        </div>
        <div className="skills-box">
          <img className="skills-icon" src={Responsive} alt="responsive icon" />
          <p className="skills-text">RESPONSIVE DESIGN</p>
        </div>
        <div className="skills-box">
          <img className="skills-icon" src={Github} alt="github icon" />
          <p className="skills-text">GITHUB</p>
        </div>
      </div>
    </div>
  );
}

export default Skills;
