import React, { useEffect, useState } from "react";
import Resume from "../../../src/Alex_Zaloga_Resume.pdf";
import text from "../../assets/Images/Alex Zaloga (1).svg";
import "./Home.scss";
import Image from "../../assets/Images/julian-hochgesang-pVikeJwYOFA-unsplash.jpg";

function Home() {
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsChrome(userAgent.indexOf("chrome") > -1);
  }, []);

  return (
    <div className="home">
      <div className="profile_container">
        <img className="profile-header" src={text} alt="" />
        <h2 className="profile-subheader">
          Full-Stack Software Engineer | Toronto{" "}
        </h2>

        <div className="profile-button__container">
          <a
            href="https://www.linkedin.com/in/alex-zaloga/"
            target="_blank"
            rel="noreferrer"
            className="profile-button"
            role="button"
          >
            <span className="profile-button__text">LinkedIn</span>
          </a>
          <a
            href="https://github.com/alexzaloga7"
            target="_blank"
            rel="noreferrer"
            className="profile-button"
            role="button"
          >
            <span className="profile-button__text">GitHub</span>
          </a>
          <a
            href={Resume}
            target="_blank"
            rel="noreferrer"
            className="profile-button"
            role="button"
          >
            <span className="profile-button__text">Resume</span>
          </a>
        </div>
      </div>

      <div className="bgImage">
        <svg className="blobCont">
          <image
            xlinkHref={Image}
            mask="url(#mask)"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
          />
          <defs>
            {isChrome && (
              <filter id="gooey" height="130%">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="15"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  result="goo"
                />
              </filter>
            )}
          </defs>
          <mask id="mask" x="0" y="0">
            <g style={{ filter: isChrome ? "url(#gooey)" : "none" }}>
              <circle
                className="blob"
                cx="10%"
                cy="10%"
                r="80"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="50%"
                cy="10%"
                r="40"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="17%"
                cy="15%"
                r="70"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="90%"
                cy="20%"
                r="20"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="30%"
                cy="25%"
                r="30"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="50%"
                cy="60%"
                r="80"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="70%"
                cy="90%"
                r="10"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="90%"
                cy="60%"
                r="90"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="30%"
                cy="90%"
                r="80"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="10%"
                cy="10%"
                r="80"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="50%"
                cy="10%"
                r="20"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="17%"
                cy="15%"
                r="70"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="40%"
                cy="20%"
                r="80"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="30%"
                cy="25%"
                r="50"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="80%"
                cy="60%"
                r="80"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="17%"
                cy="10%"
                r="150"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="17%"
                cy="10%"
                r="20"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="17%"
                cy="10%"
                r="100"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="17%"
                cy="10%"
                r="90"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="17%"
                cy="90%"
                r="80"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="17%"
                cy="90%"
                r="130"
                fill="white"
                stroke="white"
              />
              <circle
                className="blob"
                cx="87%"
                cy="70%"
                r="150"
                fill="white"
                stroke="white"
              />
            </g>
          </mask>
        </svg>
      </div>
    </div>
  );
}

export default Home;
